import styled from "react-emotion";

const SearchInput = styled.input`
    appearance: none;
    padding: 1.75em 1em 0.75em;
    width: 100%;
    height: 100%;
    border: 1px solid #A7B0CA;
    cursor: pointer;
    color: #000000;
    border-radius: 0;
    font-size: 1rem;
    font-weight: 600;
    background-color: #fff;
    box-sizing: border-box;

    &:hover {
        border: 1px solid #279AF1;
    }

    &:focus {
        border: 1px solid #0067F2;
        outline: none;
    }

    @media screen and (min-width: 768px) {
        border-right: none;
    }
`;

export default SearchInput;
