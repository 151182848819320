import React from "react";
import PropTypes from "prop-types";

import SearchInput from "./SearchInput";
import SearchLabel from "./SearchLabel";
import SearchForm from "./SearchForm";
import SearchButton from "./SearchButton";

export default class SearchBlock extends React.Component {
    state = {
        focus: false
    };

    onFocus = () => {
        this.setState(() => {
            return {
                focus: true
            };
        });
    };

    onBlur = () => {
        let input = <SearchInput />;

        if (input.valueOf.length == 0) {
            this.setState(() => {
                return {
                    focus: false
                };
            });
        }
    };

    render() {
        const { action, inputType, inputName, buttonText, label } = this.props;
        return (
            <SearchForm>
                <SearchLabel isFocus={this.state.focus}>
                    <span>{label}</span>
                    <SearchInput autoComplete="off" type={inputType} name={inputName} aria-label={label} onFocus={this.onFocus} onBlur={this.onBlur} />
                </SearchLabel>
                <SearchButton href={action} target="_blank">{buttonText}</SearchButton>
            </SearchForm>
        );
    }
}

SearchBlock.propTypes = {
    action: PropTypes.string.isRequired,
    inputType: PropTypes.string.isRequired,
    inputName: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
};
