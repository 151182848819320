import React from "react";

import SearchBlock from "./SearchBlock";
import Container from "./Container";
import Cta from "./Cta";
import DomainTitle from "./DomainTitle";

const CtaBlock = () => {

    const FormData = {
        "inputType": "text",
        "inputName": "name",
        "buttonType": "submit",
        "buttonName": "submit",
        "buttonText": "Найти домен",
        "label": "Введите домен или слово",
        "action": "https://domains.nethouse.ru/domains",
        "method": "POST"
    };

    return (
        <Cta>
            <Container>
                <DomainTitle>Выбрать доменное имя</DomainTitle>
                <SearchBlock { ...FormData } />
            </Container>
        </Cta>
    );
};

export default CtaBlock;

