import styled from "react-emotion";

const SearchForm = styled.form`
    display: flex;
    max-width: 100%;
    flex-direction: column;
    position: relative;

    @media screen and (min-width: 768px) {
        flex-direction: row;
        max-width: 70%;
        margin: 0 auto;
    }
`;

export default SearchForm;
