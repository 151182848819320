import styled from "react-emotion";

const Cta = styled.section`
    padding: 2em 0;
    background-color: #f6f6f9;
    background: rgb(246,246,249);
    background: -moz-linear-gradient(top, rgba(246,246,249,1) 0%, rgba(246,246,249,1) 100%);
    background: -webkit-linear-gradient(top, rgba(246,246,249,1) 0%,rgba(246,246,249,1) 100%);
    background: linear-gradient(to bottom, rgba(246,246,249,1) 0%,rgba(246,246,249,1) 100%);

    @media screen and (min-width: 768px) {
        padding: 3em 0;
    }
    @media screen and (min-width: 1024px) {
        padding: 4em 0;
    }
`;

export default Cta;
