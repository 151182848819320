import styled from "react-emotion";

const SearchLabel = styled.label`
    color: #7280AA;
    line-height: 1.5;
    cursor: pointer;
    font-size: 1.125em;
    font-weight: 600;
    letter-spacing: -0.02em;
    flex-grow: 1;
    margin-bottom: 1em;

    span {
        transition: all .3s ease-out;
        position: absolute;
        top: 0.9em;
        left: 1rem;

        @media screen and (min-width: 768px) {
            top: 1.12em;
        }
    }

    ${props =>
    props.isFocus &&
        ` span { font-size: .75em;
        line-height: 1rem;
        letter-spacing: .02em;
        top: .6666em;
        color: #0067F2; }`
    };

    @media screen and (min-width: 768px) {
        margin-bottom: 0;
    }
`;

export default SearchLabel;
