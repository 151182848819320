import styled from "react-emotion";

const SearchButton = styled.a`
    font-weight: 600;
    font-size: .8125em;
    line-height: 1.23;
    letter-spacing: .02em;
    text-transform: uppercase;
    cursor: pointer;
    transition: background-color .2s ease-in-out,box-shadow .2s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    appearance: none;
    color: #fff;
    background-color: #0067f2;
    padding: 2em 3em;
    text-decoration: none;

    &:hover {
        background-color: #0058f2;
    }
    &:active {
        background-color: #0046f2;
    }
`;

export default SearchButton;
